<template>
    <div class="relative">
        <div class="w-full absolute block z-30">
            <Header/>
        </div>
        <div class="space-top lbplus-careers-banner-container w-full z-25">
            <div class="relative h-full w-full flex justify-center items-end flex-col">
                <img class="absolute top-0 left-0 h-full object-cover z-5 lg:ml-20 2xl:ml-24 z-15" src="../../../assets/images/careers-page/lbplus-careers-banner.png" alt="Careers Banner">
                <div class="flex flex-col item mt-8 z-10 max-w-md lg:max-w-md 2xl:max-w-xl p-0 mobileL:p-4 text-left
                    ml-32 md:-mr-12 lg:mr-20 2xl:mr-52 md:gap-8 2xl:gap-12 leading-snug">
                    <img class="w-6 hidden mobileL:block mobileL:w-16 xl:w-20 2xl:w-24" src="../../../assets/images/home-page/flower.png" alt="Flower">
                    <h1 class="lbplus-careers-banner-title flex justify-start mobileS:text-2xl xs:text-3xl md:text-4xl lg:text-6xl 2xl:text-8xl text-white-1">
                        Careers
                    </h1>
                    <p class="font-montserrat text-sm sm:text-base md:text-lg lg:text-2xl 2xl:text-3xl pr-6 md:pr-32 lg:pr-24 2xl:pr-36 text-white-1">
                        We make today’s talent, tomorrow’s success. 
                        We do this by setting in motion what is already 
                        in our name - B.A.R.E.
                    </p>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-center bg-pink-12">
            <img class="h-32 mobileL:h-52 lg:h-72 2xl:h-96" src="../../../assets/images/careers-page/lbplus-careers-bg.png" alt="">
        </div>

        <div class="relative flex">
            <img class="relative top-0 left-0 w-full h-full object-cover z-0" src="../../../assets/images/careers-page/lbplus-hq.png">
            <div class="absolute flex flex-col items-center justify-center text-center w-full h-full">
                <div class="items-center text-white-1">
                    <h2 class="font-semibold font-mermaid text-4xl md:text-5xl lg:text-7xl 2xl:text-9xl leading-none drop-shadow-lg">HEADQUARTER<br>ROLES</h2>  
                    <button @click="toggleTabs" class="rounded-full font-semibold bg-pink-14 justify-center text-white-1 mt-2 mobileL:mt-4 py-2 md:py-1 xl:py-2 px-16 md:px-6 text-sm md:text-base xl:text-lg 2xl:text-xl drop-shadow-lg">
                        View Opportunities
                    </button>
                </div> 
            </div>  
        </div>

        <div v-if="showTabs" class="font-bold font-poppins text-white-1 text-xs leading-tight lg:leading-normal lg:text-lg flex items-center
        justify-between py-2 gap-2 mobileL:gap-4 px-3 mobileL:px-8 max-h-40 overflow-y-auto bg-green-9 mobileL:text-base 2xl:text-2xl">
            <button @click="selectTab(1)" :class="{ 'lbplus-active-tab': selectedTab === 1 }" class="tab-title">
                Marketing and Creatives Manager
            </button>
            <button @click="selectTab(2)" :class="{ 'lbplus-active-tab': selectedTab === 2 }" class="tab-title">
                Business Development Officer
            </button>
            <button @click="selectTab(3)" :class="{ 'lbplus-active-tab': selectedTab === 3 }" class="tab-title">
                Accounting Analyst
            </button>
            <button @click="selectTab(4)" :class="{ 'lbplus-active-tab': selectedTab === 4 }" class="tab-title">
                Learning and Development Supervisor
            </button>
            <button @click="selectTab(5)" :class="{ 'lbplus-active-tab': selectedTab === 5 }" class="tab-title">
                UI/UX Designer
            </button>
        </div>
        <div v-if="selectedTab !== null && showTabs" class="max-h-72 lg:max-h-130 overflow-y-auto">
            <div class="lbplus-tab-content text-white-1 bg-pink-12 px-10 mobileL:px-24 py-4">
                <div v-if="selectedTab === 1">
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Position Summary</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            The Marketing and Creative Manager is responsible for the development and supervision 
                            of all marketing and communications strategies for the company. 
                            This individual will oversee the development of goals, strategies, and implementation 
                            plans to execute comprehensive marketing campaigns. 
                            The incumbent will promote the creation, enhancement and development of programs 
                            to promote the company and its services; strategically maintaining 
                            brand integrity while establishing the company's status as an industry leader. 
                            The Marketing and Creative Manager will also oversee the launch and 
                            managing of new products, services, and associated brands to meet profitability goals and targets.
                        </p>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Work Experience</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            Minimum of 5 years experience in a managerial role in digital marketing or 
                            advertising position. Has a solid experience in Marketing and Brand Management.
                        </p>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Education</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight">
                            Minimum of 5 years experience in a managerial role in digital marketing or 
                            advertising position. Has a solid experience in Marketing and Brand Management.
                        </p>   
                    </div>
                </div>
                <div v-if="selectedTab === 2">
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Position Summary</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            The Business Development Officer is responsible in managing account assignment, 
                            its day-to-day tasks, and develop and execute strategies 
                            to increase company sales and profits. The BD Officer is also assigned in 
                            analyzing opportunities to contribute to business growth by 
                            acquiring long-term partnerships, analyzing results and managing retention.
                        </p>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Educational Background</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            Graduate of any Business Course or any related study
                        </p>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Experience Requirement</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            With at least 2-3 years Sales and Marketing or Business Development experience
                        </p>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Competency Requirement</h3>
                        <ul class="list-disc ml-8 text-sm lg:text-xl 2xl:text-3xl leading-tight">
                            <li>Thorough assessment of data</li>
                            <li>Concise report of progress or setbacks</li>
                            <li>Continuous implementation of initiatives</li>
                            <li>Continuous generation of ideas to contribute to business growth</li>
                            <li>Communication Skills</li>
                            <li>Presentation Skills</li>
                        </ul>
                    </div>
                </div>
                <div v-if="selectedTab === 3">
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Position Summary</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            This Accounting Analyst position focuses on the accounting of branch 
                            sales and is mainly responsible for the timely monitoring and accurate 
                            recording of all collections deposited by the branches. The Analyst 
                            is also responsible for the reconciliation of bank transactions, 
                            and other tasks assigned by the Accounting Supervisor and/or Senior Finance Manager.
                        </p>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Competency Requirement</h3>
                        <ul class="list-disc ml-8 text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            <li>With initiative and can work with minimum supervision</li>
                            <li>Independent and self-disciplined</li>
                            <li>Detailed and organized</li>
                            <li>With good accounting knowledge</li>
                            <li>Knowledgeable in MS Office especially Excel</li>
                        </ul>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Experience Requirement</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            1 year work experience
                        </p>
                    </div>
                </div>
                <div v-if="selectedTab === 4">
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Position Summary</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            The L&D Supervisor applies instructional design strategies 
                            and methodologies, as well as content writing expertise, 
                            to deliver high-quality training solutions and content 
                            across all levels in the organization.
                        </p>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Qualifications</h3>
                        <ul class="list-disc ml-8 text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            <li>Bachelor’s degree/certification in any of the following (or similar) 
                                fields is a plus, but not required: Educational Technology, Distance Education, 
                                Instructional Design, Journalism, Technical Writing, Copy writing 
                                English, Communication Arts, Mass Communication.</li>
                            <li>At least 2 years of work experience in the related field</li>
                            <li>Experience in any of the following fields is a plus: training delivery, 
                                content creation, e-learning development</li>
                            <li>Knowledge in systems and tools used in development of online training 
                                content. Knowledge of adult learning principles</li>
                        </ul>
                    </div>
                </div>
                <div v-if="selectedTab === 5">
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Position Summary</h3>
                        <p class="text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            UX Designer will design software and platforms that meet the project owner’s needs. 
                            Being analytical and creative is required for this role in 
                            order to meet the and grasp the needs of the project.
                        </p>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Experience Requirement</h3>
                        <ul class="list-disc ml-8 text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            <li>1-3 years of experience as a UX designer or similar role.</li>
                            <li>Portfolio of designed projects.</li>
                        </ul>
                    </div>
                    <div>
                        <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Qualifications</h3>
                        <ul class="list-disc ml-8 text-sm lg:text-xl 2xl:text-3xl leading-tight mb-4">
                            <li>Creative and conceptual thinker</li>
                            <li>Accuracy and attention to detail</li>
                            <li>Proficient in photoshop, Adobe XD and other design and wireframing tools</li>
                            <li>Up-to-date with the latest UX trends, techniques and technologies</li>
                            <li>Ability to present work collaboratively as well as explaining rationales behind the design.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="relative flex">
            <img class="relative top-0 w-full h-full object-cover z-0" src="../../../assets/images/careers-page/lbplus-salon.png">
            <div class="absolute flex flex-col items-center justify-center text-center w-full h-full">
                <div class="items-center text-white-1">
                    <h2 class="font-semibold font-mermaid text-4xl md:text-5xl lg:text-7xl 2xl:text-9xl leading-none">SALON<br>OPPORTUNITIES</h2>  
                    <button @click="toggleSalonTabs" class="rounded-full font-semibold bg-green-12 justify-center text-white-1 mt-2 mobileL:mt-4 py-2 md:py-1 xl:py-2 px-16 md:px-6 text-sm md:text-base xl:text-lg 2xl:text-xl drop-shadow-lg">
                        View Opportunities
                    </button>
                </div> 
            </div>  
        </div>
        <div v-if="showSalonTabs" class="font-bold font-poppins text-white-1 flex items-center justify-center py-2 gap-4 px-8 
        max-h-40 overflow-y-auto bg-green-9 2xl:text-2xl text-sm mobileL:text-lg ">
            <button @click="selectSalonTab(1)" :class="{ 'lbplus-active-tab': selectedSalonTab === 1 }" class="tab-title ">
                Wax Professional
            </button>
        </div>
        <div v-if="selectedSalonTab !== null && showSalonTabs" class="">
            <div class="tab-content text-white-1 bg-green-12 px-10 mobileL:px-24 py-4">
                <div v-if="selectedSalonTab === 1">
                    <h3 class="font-semibold text-lg lg:text-2xl 2xl:text-4xl mb-3">Requirement</h3>
                    <ul class="list-disc ml-8 text-sm lg:text-xl 2xl:text-3xl leading-tight">
                        <li>Patient, trustworthy and customer service oriented with at least 1-2 
                            years of experience in the salon & spa industry.</li>
                        <li>Must be TESDA accredited or Certificate in Waxing services holder.</li>
                        <li>Highly competent in providing waxing services.</li>
                        <li>Willing to be trained in San Juan, Metro Manila.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
    </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/LaybarePlusFooter.vue'

export default {
    components: {
        Header,Footer
    },
    mounted() {
        this.$gtag.pageview('laybareplus/career')
    },
    data() {
        return {
        showTabs: false,
        selectedTab: null,
        showSalonTabs: false,
        selectedSalonTab: null
        };
    },
    methods: {
        toggleTabs() {
            this.showTabs = !this.showTabs;
        },
        toggleSalonTabs() {
            this.showSalonTabs = !this.showSalonTabs
        },
        selectTab(tabNumber) {
            this.selectedTab = this.selectedTab === tabNumber ? null : tabNumber;
        },
        selectSalonTab(tabNumber) {
            this.selectedSalonTab = this.selectedSalonTab === tabNumber ? null : tabNumber;
        },
    },
}
</script>
<style>
.lbplus-careers-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #f6c5c9 0%, #fcbfc6 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .lbplus-careers-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

.lbplus-careers-banner-title {
    color: #FFF;
    text-align: center;
    font-family: Mermaid;
    font-style: normal;
    font-weight: 600;
}

@media (min-width: 1536px) {
    .lbplus-careers-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .lbplus-careers-banner-container{
    height:255px;
    }
    .space-top {
    margin-top: 60px;
    }
}

.lbplus-tab-item {
  flex: 1;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  margin-right: 8px;
}
.lbplus-active-tab {
  color: #67983F;
}
</style>